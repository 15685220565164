import React, { useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Container } from "react-bootstrap";
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import {OpenAI} from 'openai';
import StepCard from '../../components/StepCard/StepCard';
import image1 from '../../images/image1.png';
import image2 from '../../images/image2.png';
import image3 from '../../images/image3.png';
import image4 from '../../images/image4.png';
import image5 from '../../images/image5.png';
import image6 from '../../images/image6.png';
import image7 from '../../images/image7.png';
import Accordion from '../../components/Accordion/Accordion';
import thread from '../../images/Thread.png';
import needle from '../../images/needle.png';
import scissors from '../../images/scissors.png';

import '../Steps/StepsStyling.css';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});



async function gptImage(stepContent){
  const response = await openai.images.generate({
    model : 'dall-e-3',
    prompt : stepContent,
    n: 1,
    size : '1024x1024',
  })
 return response.data[0].url;
}


export const Steps = () => {
  const navigate = useNavigate();

  const stepDicti = [
    { stepNum: 1, stepContent: 'Lay the sweatshirt flat with the front facing up. Cut off the bottom band, both sleeves, and the armbands.', imgPath: image1 },
    { stepNum: 2, stepContent: 'Use a rotary cutter to cut both the front and back of the sweatshirt to 18” wide and 14 ½” tall. Cut the sleeves open along the seam line and cut a 4 ½” strip from each sleeve for the bag side panels.', imgPath: image2 },
    { stepNum: 3, stepContent: 'Cut an 18” by width of fabric strip from the cotton fabric, then sub-cut to make two 18” x 14 ½” rectangles. Cut a 15” x 12 ½” rectangle for the interior pocket, and a 4 ½” by width of fabric strip for the side panel.', imgPath: image3 },
    { stepNum: 4, stepContent: 'Stack the front and back pieces of the sweatshirt and lining with wrong sides together. Cut a curve on the two bottom corners through all layers. Sew the short ends of the sweatshirt side pieces together to make one long strip.', imgPath: image4 },
    { stepNum: 5, stepContent: 'Fold the 15” x 12 ½” rectangle in half, serge the short sides, and stitch the bottom, leaving an opening for turning. Turn the pocket right side out, press, and stitch onto the lining, marking and sewing divider lines.', imgPath: image5 },
    { stepNum: 6, stepContent: 'Add the bag back, lining up the top edges and pressing seam allowances away from the side panel. Clip the folded band to the bag top with the buttonhole centered.', imgPath: image6 },
    { stepNum: 7, stepContent: 'Cut two 25” lengths of 1” wide webbing, fold under 1/2" on each end, and pin them in place 3” from the nearest side seam. Thread the twill tape through the casing and tie a knot at each end.', imgPath: image7 },
  ];

  const handleCheckboxClick = () => {
    navigate('/userimpact');
  }



  return (
    <Container>
      <div 
        style={{
          padding: "20px",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: "scroll",
          background: "FloralWhite", 
          
        }}
      ></div>
      <Header className = 'header'>
        <Accordion BackButton={<span className="backArrow">←</span>} />
      </Header>

      <Content>
        <h2>Steps: T-shirt to tote bag</h2>

        
          <Row>
          <ToolsHeading>Tools required: </ToolsHeading>
          </Row>
          <Row>
          <ToolsCard>
             Scissors
             <ToolImage>
             <img src={scissors} alt='Scissors'></img>
             </ToolImage>
          </ToolsCard> 
          <ToolsCard>
            Thread
            <ToolImage>
            <img src={thread} alt='Thread'></img>
            </ToolImage>
          </ToolsCard>
          <ToolsCard> 
            Needle
            <ToolImage>
            <img src={needle} alt='Needle'></img>
            </ToolImage>
          </ToolsCard>
          </Row>
       
        
        <div className='step-cards'>
          {stepDicti.map(step => (
            <StepCard
              key={step.stepNum}
              stepNum={<h2>{step.stepNum}</h2>}
              stepContent={<p>{step.stepContent}</p>}
              imgPath={step.imgPath}
            />
          ))}
        </div>

        <div>Voila! You're done!</div>
        <div className="buttons">
          <Row>
            <Col>
              <FileUploadWrapper>
                <FileInput id="fileUpload" type="file" />
                <FileInputLabel htmlFor="fileUpload">📷</FileInputLabel>
                <StyledLabel>Upload your finished project</StyledLabel>
              </FileUploadWrapper>
            </Col>
            <Col>
              <CustomCheckboxWrapper>
                <StyledCheckbox type="checkbox" onClick={handleCheckboxClick} />
                <StyledLabel>Mark the project completed</StyledLabel>
              </CustomCheckboxWrapper>
            </Col>
          </Row>
        </div>
      </Content>
    </Container>
  );
};

const Header = styled.h1`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
`;

const Content = styled.div`
  padding: 100px 20px 20px 20px; 
  position: relative;
  overflow: auto;

`;

const ToolImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow:hidden;
  
  img{
  width: 150%;  
  height: 150%; 
  display: block;
  object-fit: contain;
  position: relative;
  left: 50%
  top: 50%;
  
  }
 
`;

const ToolsHeading = styled.h4`
  flex: 1;
  margin: 0;
  font-size: 20px;
  color: black;
`;

const ToolsCard = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-item:center;
  padding:10px;
  gap: 0px;
  background-color: #FDF5E6;
  width: auto;
  max-width: 100px;
  height: auto;
  max-height: 100px;
  border-radius: 8px; 
  border: 2px solid #3d4c1b; 
  border-color: #8B4513;
  margin:10px;
`;

const CustomCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCheckbox = styled.input`
  width: 3rem; /* 3 times the default width */
  height: 3rem; /* 3 times the default height */
  margin-bottom: 0.5rem;
`;

const StyledLabel = styled.label`
  margin-top: 0.5rem;
`;

const FileUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;

const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  width: 3rem; /* 3 times the default width */
  height: 3rem; /* 3 times the default height */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  cursor: pointer;
  background-color: #f8f9fa;
  margin-bottom: 0.5rem;
  text-align: center;
  font-size: 0.75rem;
`;

export default Steps;
