import React, {useState} from 'react';
import './SignInStyling.css';
import SignupService from '../../services/SignupService';

function SignIn() {
  const [credentials, setCredentials] = useState({
  username : '',
  password : '',
  });
  const handleLogin = (e) => {
    e.preventDefault();
    
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => 
      ({ ...credentials, [name]: value }));
   }

  const handleRegister = () => {
    // 
  };

  const handleGoogleLogin = () => {
    // 
  };

  const handleAppleLogin = () => {
    //
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="login-image">
            
          <img src="https://static.vecteezy.com/system/resources/previews/022/210/039/original/world-environment-day-reduce-reuse-recycle-vector.jpg" alt="Profile" />

        </div>
        <h1>Welcome!</h1>
        <form onSubmit={handleLogin}>
          <input type="text" name="username" placeholder="Email or username" onChange={handleInputChange} required />
          <input type="password" name = "password" placeholder="Password" required />
          <button type="submit">Log in</button>
        </form>
        <div className="register-link" onClick={handleRegister}>
          New user? <span>Register Now</span>
        </div>
        <div className="other-methods">
          <div className="divider">
            <hr /> <span>Use other methods</span> <hr />
          </div>
          <div className="icons">
            <div className="icon" onClick={handleGoogleLogin}>
                <img className="login-icon" src="https://th.bing.com/th/id/R.0fa3fe04edf6c0202970f2088edea9e7?rik=joOK76LOMJlBPw&riu=http%3a%2f%2fpluspng.com%2fimg-png%2fgoogle-logo-png-open-2000.png&ehk=0PJJlqaIxYmJ9eOIp9mYVPA4KwkGo5Zob552JPltDMw%3d&risl=&pid=ImgRaw&r=0" alt="Google Login" />
            </div>

            <div className="icon" onClick={handleAppleLogin}>
                <img className="login-icon"src="https://logospng.org/download/apple/logo-apple-1536.png" alt="Apple Login" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;