import React from 'react';
import { Navbar, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import '../Accordion/AccordionStyling.css';

export const Accordion = () => {
  return (
    <nav className="navbar">
      <Navbar className="nav-drop-down" expand="lg">
        <NavDropdown
          title={<span className="nav-dropdown-title">Get help</span>}
          alt="navbar drop down"
          className="nav-dropdown"
        >
          <LinkContainer to="/Help">
            <NavDropdown.Item className="nav-dropdown-item">
              Ask Community
            </NavDropdown.Item>
          </LinkContainer>

          <LinkContainer to="/FindTools">
            <NavDropdown.Item className="nav-dropdown-item">
              Find Tools
            </NavDropdown.Item>
          </LinkContainer>
        </NavDropdown>
      </Navbar>
    </nav>
  );
};

export default Accordion;
