import React from "react";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import profileImg from "../../images/profile-user.png";
import "./DesignSummaryStyling.css";
import TshirtTote from "../../images/Tshirt-tote.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const DesignScreen = () => {
  const nav = useNavigate();
  return (
    <Container flex class="container">
      <div className="header">
        <span className="back-arrow" onClick={() => nav("/designSearch")}>
          ←
        </span>
        <img src={profileImg} alt="" className="profile-icon" />
      </div>
      <div className="card">
        <div class="title-section">
          <h1> T-Shirt</h1>
          <h2> Tote Bag</h2>
        </div>

        <img class="tote-img" src={TshirtTote} alt="Tote Bag"></img>

        <div className="tools">
          <h2>Tools:</h2>
          <ul>
            <li> T-Shirt</li>
            <li> Scissors</li>
            <li> Needle </li>
            <li> Thread </li>
          </ul>
        </div>
        <Row>
          <Col>
            <Link to="/Steps">
              <button className="diy-button"> DIY!</button>
            </Link>
          </Col>
          <Col>
            <Link to="/FindTools">
              <button className="findtools"> Find tools</button>
            </Link>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default DesignScreen;
