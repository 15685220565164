import React, { useState } from 'react';
import { Container, Row, Col, Modal, Button, Navbar, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './ImpactPageStyling.css';
import WaterIcon from '../../images/water-drop.png';
import html2canvas from 'html2canvas';
import NavbarIcon from '../../images/navbar_dropdown.png';  // Make sure this path is correct

const LocalNavBar = () => (
    <Navbar className="navbar navbar-expand-lg navbar-light bg-light" expand="lg" style={{ justifyContent: 'start', paddingLeft: '350px', backgroundColor:'white' }}>
            <NavDropdown title={<img src={NavbarIcon} alt="Navbar Drop Down" style={{ width: '50px' }} />} id="basic-nav-dropdown">
                <LinkContainer to='/ItemScan'>
                    <NavDropdown.Item>Scan Cloth</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='*'>
                    <NavDropdown.Item>Projects</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/Community'>
                    <NavDropdown.Item>Build Community</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/UserImpact'>
                    <NavDropdown.Item>Personal Impact</NavDropdown.Item>
                </LinkContainer>
            </NavDropdown>
    </Navbar>
);

export const Impact = () => {
    const [screenshot, setScreenshot] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const handleScreenshot = () => {
        const element = document.querySelector('.content-container');
        html2canvas(element).then((canvas) => {
            setScreenshot(canvas.toDataURL('image/png'));
            setShowModal(true);
        });
    };

    const handleShare = (platform) => {
        if (!screenshot) return;
        const url = encodeURIComponent(screenshot);
        let shareUrl = '';

        switch (platform) {
            case 'facebook':
                shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
                break;
            case 'twitter':
                shareUrl = `https://twitter.com/intent/tweet?url=${url}`;
                break;
            case 'linkedin':
                shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;
                break;
            default:
                break;
        }

        window.open(shareUrl, '_blank');
    };

    return (
        <Container fluid className='content-container'>
            <Row style={{ textAlign: 'center' }}>
            <LocalNavBar />
            <h1 style={{ marginBottom: '10px', marginTop:'240px' }}>Woohoo!</h1>
            <p style={{ margin: 0 }}>Congratulations on finishing your project!</p>
            </Row>
            <Row>
                <Col md={12} className='content-container'>
                    <div className='card'>
                        <h2 className='cardHeader'>Environmental Impact:</h2>
                        <div className='card-content'>
                            <img className="water-img" src={WaterIcon} alt="Water Icon"></img>
                            <div>
                                <span>Saved 50 Gallons of Water</span>
                            </div>
                            <div>
                                <span>Badges Collected: Place Holder</span>
                            </div>
                        </div>
                    </div>
                    <div className='card'>
                        <h2 className='cardHeader'>Project History:</h2>
                        <div className='card-two-content'>
                            <div className="growth-chart">📈</div>
                            <div>
                                <span>Improvement: X%</span>
                            </div>
                        </div>
                    </div>
                    <div className='card'>
                        <h2 className='cardHeader'>Community Impact:</h2>
                        <div className='card-three-content'>
                            <div className="shirtIcon">👕</div>
                            <div>
                                <span>X Garments Traded</span>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className='share-button-container'>
                <Button className="share-button" onClick={handleScreenshot}>Share on Social Media</Button>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)} className="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Screenshot Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {screenshot && (
                        <div className='screenshot-preview'>
                            <img src={screenshot} alt="Screenshot Preview" className="img-fluid" />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleShare('facebook')}>
                        <i className="fab fa-facebook fa-2x"></i>
                    </Button>
                    <Button variant="primary" onClick={() => handleShare('twitter')}>
                        <i className="fab fa-twitter fa-2x"></i>
                    </Button>
                    <Button variant="primary" onClick={() => handleShare('linkedin')}>
                        <i className="fab fa-linkedin fa-2x"></i>
                    </Button>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default Impact;
