import {db} from "./firebase";
import Design from "../schema/Design";

import {
  collection,
  addDoc,
  getDocs,
  query,
  doc,
  where,
  getDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

const patternsRef = collection(db, "designs");

class PatternService {
  /**
   *
   * @param {Design} pattern
   */
  static async createPattern(pattern) {
    const docRef = await addDoc(patternsRef, pattern);
    return docRef.id;
  }

  /**
   * Gets a design based on a name
   * @param {String} design_name
   * @returns {Promise<Design> || null}
   *
   */
  static async getPattern(design_name) {
    const q = query(patternsRef, where("name", "==", design_name));
    const docSnap = await getDocs(patternsRef, q);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  }

  /**
   * @param {number} limit
   * @returns {Promise<Array<Design>>}
   */
  static async getPatterns(limit) {
    const querySnapshot = await getDocs(
      query(patternsRef, limit ? limit : undefined)
    );
    const patterns = [];
    querySnapshot.forEach((doc) => {
      patterns.push(doc.data());
    });
    return patterns;
  }

  /**
   * @param {String} id
   * @param {Design} pattern
   */
  static async updatePattern(id, pattern) {
    await updateDoc(doc(patternsRef, id), pattern);
  }

  /**
   * @param {String} design
   */
  static async deletePattern(id) {
    await deleteDoc(doc(patternsRef, id));
  }
}

export default PatternService;
