import React from 'react';
import styled from 'styled-components';

export const ProjectDone = () => {
  return (
    <Container>
      <Header>
        <BackButton>←</BackButton>
        <HeaderContent>
          <Title>Woohoo!</Title>
          <CongratulationsText>Congratulations on finishing (your first)/(another) project!</CongratulationsText>
        </HeaderContent>
        <UserIcon>👤</UserIcon>
      </Header>
      <PastProjectsButton>Past Projects →</PastProjectsButton>
      <SavingsSection>
        <SectionTitle>How have you saved the planet?</SectionTitle>
        {['Water saved', 'Landfill reduced', 'Carbon footprint', 'Underpaid labor impacted'].map((item, index) => (
          <SavingsItem key={index}>
            <Icon className={`icon-${index}`} />
            <ItemText>{item} ____________</ItemText>
          </SavingsItem>
        ))}
      </SavingsSection>
    </Container>
  );
};

const Container = styled.div`
  max-width: 400px;
  margin: auto;
  background: #fff;
  color: #000;
  padding: 20px;
  border-radius: 8px;
  font-family: Arial, sans-serif;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
`;

const BackButton = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const HeaderContent = styled.div`
  text-align: center;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 36px;
`;

const CongratulationsText = styled.p`
  font-size: 16px;
`;

const UserIcon = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const PastProjectsButton = styled.button`
  display: block;
  margin: 10px auto;
  padding: 10px 20px;
  border: 2px solid #000;
  background: grey;
  font-size: 16px;
  cursor: pointer;
`;

const SavingsSection = styled.div`
  text-align: left;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
`;

const SavingsItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  background-color: #ccc;
  margin-right: 10px;
  
  &.icon-0 { background: url('/path-to-water-icon.png') no-repeat center/cover; }
  &.icon-1 { background: url('/path-to-landfill-icon.png') no-repeat center/cover; }
  &.icon-2 { background: url('/path-to-carbon-icon.png') no-repeat center/cover; }
  &.icon-3 { background: url('/path-to-labor-icon.png') no-repeat center/cover; }
`;

const ItemText = styled.p`
  margin: 0;
`;
