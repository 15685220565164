import { OpenAI } from "openai";
import React, { useState } from "react";

// Initialize OpenAI client
const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

async function getGarmentSuggestions(garment, material, color) {
  const prompt = `I have a ${garment} made of ${material} and it is ${color}. Provide a table of projects that I can do with this. In the response, include tools, expected time, and a single line of the steps separated by commas included for making this garment. Format this into HTML. Do not include a delimiter on the start or end of a given entry`;

  try {
    const response = await openai.completions.create({
      model: "gpt-3.5-turbo-instruct",
      prompt: prompt,
      max_tokens: 150,
      n: 1,
      stop: null,
      temperature: 0.7,
    });

    const suggestions = response.choices[0].text.trim();
    console.log("Suggestions:", suggestions);
    return parseSuggestionsToTable(suggestions);
  } catch (error) {
    console.error("Error fetching suggestions:", error);
    return null;
  }
}

function parseSuggestionsToTable(suggestions) {
  const lines = suggestions.split("\n");
  const table = lines.slice(1).map((line) => {
    const [task, tools, time, steps] = line
      .split("|")
      .map((item) => item.trim());
    return { task, tools, time, steps };
  });
  console.log(table);
  return table;
}

// Example usage

const GarmentBox = () => {
  const [garment, setGarment] = useState("");
  return (
    <div>
      <h1>Garment Suggestions</h1>
      <p>
        {"Enter a garment type, material, and color to get suggestions for making the garment."}
      </p>
      <button
        onClick={() =>
          getGarmentSuggestions("shirt", "cotton", "blue").then((table) => {
            console.table(table);
            setGarment(table);
          })
        }
      >
        Get Suggestions
      </button>
    </div>
  );
};

export default GarmentBox;
export { getGarmentSuggestions };
