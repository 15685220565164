import React from 'react';
import {Navbar, NavDropdown} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './NavbarComponentStyling.css';
import NavbarIcon from '../../images/navbar_dropdown.png';

// to change title into image/icon
// title={<img src={logo} alt="Logo" style={{ width: '30px' }} />}

export const NavBar = ()=>{

    return (
        <Navbar  class ='nav-drop-down' className="navbar navbar-expand-lg navbar-light bg-light" expand="lg">
            <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                        <NavDropdown title= {<img src= {NavbarIcon} alt= "navbar drop down" style= {{width:'50px'}} />}
                          id="basic-nav-dropdown">

                            <LinkContainer to ='/ItemScan'>
                                <NavDropdown.Item> Scan Cloth</NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to ='*'>
                                <NavDropdown.Item> Projects</NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to ='/Community'>
                                <NavDropdown.Item> Build Community </NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to ='/UserImpact'>
                                <NavDropdown.Item> Personal Impact </NavDropdown.Item>
                            </LinkContainer>

                        </NavDropdown>

                </Navbar.Collapse>

            </Navbar>
    
    )
}

export default NavBar
