import { db, auth } from "./firebase";
import User from "../schema/User";
import {
  OAuthProvider,
  getAuth,
  signInWithPopup,
  onAuthStateChanged,
  updateProfile,
} from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import PatternService from "./PatternService";

const appleProvider = new OAuthProvider("apple.com");
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in
    console.log("User ID:", user.uid);
    console.log("User Email:", user.email);
  } else {
    // User is signed out
    console.log("No user is signed in.");
  }
});

class SignupService {
  static async signupUser(email, password) {
    try {
      // Create user with email and password
      const userCredential = await auth.createUserWithEmailAndPassword(
        email,
        password
      );

      // Add user data to Firestore
      await db.collection("users").doc(userCredential.user.uid).set({
        email: userCredential.user.email,
        createdAt: db.FieldValue.serverTimestamp(),
      });

      return userCredential.user;
    } catch (error) {
      throw new Error("Failed to signup user");
    }
  }

  static async authenticateUser(email, password) {
    try {
      // Sign in user with email and password
      const userCredential = await auth.signInWithEmailAndPassword(
        email,
        password
      );
      return userCredential.user;
    } catch (error) {
      throw new Error("Failed to authenticate user");
    }
  }

  static async authenticateWithApple() {
    try {
      // Sign in user with Apple
      const userCredential = await signInWithPopup(getAuth(), appleProvider)
        .then((result) => {
          // The signed-in user info.
          const user = result.user;

          // Apple credential
          const credential = OAuthProvider.credentialFromResult(result);
          const accessToken = credential.accessToken;
          const idToken = credential.idToken;

          // IdP data available using getAdditionalUserInfo(result)
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The credential that was used.
          const credential = OAuthProvider.credentialFromError(error);

          // ...
        });
      return userCredential.user;
    } catch (error) {
      throw new Error("Failed to authenticate user with Apple");
    }
  }
}

export default SignupService;
