import React, { useState } from "react";
import styled from "styled-components";
import '@fontsource/space-grotesk'; // Import Space Grotesk font

export const Help = () => {
  const [problem, setProblem] = useState("");
  const [problems, setProblems] = useState([
    {
      id: 1,
      text: "How to stitch the bag handles onto the bag?",
      solutions: [
        {
          user: "Naumi",
          text: "Choose durable, textured fabrics like cotton, denim, or linen for handle durability. Prepare the fabric by straightening, cutting to size, adding seam allowances, and inserting interfacing.",
        },
        {
          user: "Chase",
          text: "You can do this! This part seems tricky but with the right procedure, you will definitely master it! Here is a helpful link: (https://quiltsocial.com/how-to-sew-on-handles-to-a-quilted-tote-bag/)",
        },
      ],
    },
    {
      id: 2,
      text: "How to implement a Chainstich?",
      solutions: [
        {
          user: "Alison",
          text: "To sew a chain saw stitch on a sewing machine stitch, hold the end of the thread in your hand and pull it through the needle. As you sew, the chain stitch attachment will create a loop of thread!",
        },
        {
          user: "Shirley",
          text: "Hey! If you find it too difficult, you can also always just use normal stitch on your sewing machine. I found that equally sturdy!",
        },
        {
          user: "Alexis",
          text: "Here a tutorial of the Chainstitch that was helpful for me: (https://www.sewdaily.com/sewing/serger-school-chainstitch-basics/).",
        },
      ],
    },
  ]);

  const handleProblemSubmit = (e) => {
    e.preventDefault();
    setProblems([
      ...problems,
      { id: problems.length + 1, text: problem, solutions: [] },
    ]);
    setProblem("");
  };

  const handleSolutionSubmit = (postId, solution) => {
    setProblems(
      problems.map((problem) => {
        if (problem.id === postId) {
          return {
            ...problem,
            solutions: [...problem.solutions, solution],
          };
        }
        return problem;
      })
    );
  };

  return (
    <Container>
      <h2 style={{ color: '#3d4c1b' }}>Help Page</h2>
      <Form onSubmit={handleProblemSubmit}>
        <FormGroup>
          <Label htmlFor="problem">Post your problem:</Label>
          <Textarea
            id="problem"
            value={problem}
            onChange={(e) => setProblem(e.target.value)}
            required
          />
        </FormGroup>
        <SubmitButton type="submit">Submit</SubmitButton>
      </Form>
      <ProblemsSection>
        <h3 style={{ color: '#3d4c1b' }}>Problems posted by users:</h3>
        {problems.map((p) => (
          <Problem key={p.id}>
            <ProblemText>{p.text}</ProblemText>
            {p.solutions.length === 0 ? (
              <WaitingForSolutions>Waiting for solutions...</WaitingForSolutions>
            ) : null}
            <Solutions>
              <h5 style={{ color: '#3d4c1b' }}>Solutions</h5>
              {p.solutions.map((solution, index) => (
                <Solution key={index}>
                  <UserName>{solution.user}</UserName>: {solution.text}
                </Solution>
              ))}
              <SolutionForm postId={p.id} onSubmit={handleSolutionSubmit} />
            </Solutions>
          </Problem>
        ))}
      </ProblemsSection>
    </Container>
  );
};

const SolutionForm = ({ postId, onSubmit }) => {
  const [solution, setSolution] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(postId, { user: "Anonymous", text: solution });
    setSolution("");
  };

  return (
    <form onSubmit={handleSubmit}>
      <SolutionInput
        type="text"
        value={solution}
        onChange={(e) => setSolution(e.target.value)}
        required
      />
      <SubmitSolutionButton type="submit">Add Solution</SubmitSolutionButton>
    </form>
  );
};

const Container = styled.div`
  padding: 20px;
  max-width: 600px;
  margin: auto;
  font-family: 'Space Grotesk', sans-serif;
  background: FloralWhite;
  height: 100vh;
  overflow: scroll;
`;

const Form = styled.form`
  margin-bottom: 20px;
  padding: 15px;
  background: #D2B48C;
  border: 2px solid #D2B48C;
  border-radius: 8px;
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: #3d4c1b;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 0.5rem;
  background: #FFFAF0;
  border: 1px solid #3d4c1b;
  border-radius: 4px;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background: #3d4c1b;
  color: white;
  border: 2px solid #3d4c1b;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background: #495057;
  }
`;

const ProblemsSection = styled.div`
  margin-top: 2rem;
`;

const Problem = styled.div`
  margin-bottom: 1.5rem;
  padding: 15px;
  background: #FAF0E6;
  border: 2px solid #3d4c1b;
  border-radius: 8px;
`;

const ProblemText = styled.p`
  font-weight: bold;
  color: #3d4c1b;
`;

const WaitingForSolutions = styled.p`
  color: #999;
  font-style: italic;
`;

const Solutions = styled.div`
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 2px solid #3d4c1b;
`;

const Solution = styled.div`
  margin-top: 0.5rem;
  color: #3d4c1b;
`;

const UserName = styled.span`
  font-weight: bold;
  color: #3d4c1b;
`;

const SolutionInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background: #FFFAF0;
  border: 1px solid #3d4c1b;
  border-radius: 4px;
`;

const SubmitSolutionButton = styled.button`
  padding: 10px 20px;
  background: #3d4c1b;
  color: white;
  border: 2px solid #3d4c1b;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background: #495057;
  }
`;

export default Help;
