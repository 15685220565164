import React from 'react';
import { useNavigate } from 'react-router-dom';
 
import Pillow from '../images/pillow.png';
import Quilt from '../images/Quilt.png';
import Tote from '../images/tote.png';
import Scarf from '../images/scarf.png';
import Plushie from '../images/Plushie.png';
import Beanies from '../images/Beanie.png';
import Bed from '../images/pet-bed.png';
import Rug from '../images/rug.png';
import Scrunchie from '../images/Scrunchie.png';
import NavBar from  '../components/NavBar/NavbarComponent';
import Logo from '../images/logo.png';
 
 
import styled from 'styled-components';
 
 
 
const Welcome = () => {
  const navigate =  useNavigate();
 
  const handleNavigation = () => navigate('/itemscan');
 
  const prefImg= [
    {id:1, path: Pillow, label: 'Pillow'},
    {id:2,path: Scarf, label:'Scarf'},
    {id:3, path: Tote, label: 'Tote'},
    {id:4, path: Beanies, label:'Beanie'},
    {id:5, path: Quilt, label: 'Quilt'},
    {id:6, path: Rug, label:'Rug'},
    {id:7, path: Bed, label:'PetBed'},
    {id:8, path: Scrunchie, label:'Scrunchie'},
    {id:9, path: Plushie, label:'Plushie'}
  ]
  return (
    <OuterContainer key='outercontainer'>
      <div key='container-styling'
        style={{
          padding: "20px",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: "scroll",
          background: "rgb(176, 193, 176)", 
          
        }}
      >
        <Header key= 'navbar'>
          <NavBar></NavBar>
        </Header>
      <ContentContainer key='content-container'>

        <WelcomeHeader key='welcome-header'>
            <h2 style={{fontSize:'30px', marginTop:'60px'}}> Welcome to FlipIt</h2>
            <img src={Logo} alt='logo'></img>
        </WelcomeHeader>
 
         
        <ImgGrid key='image-grid'>
        {prefImg.map((item) => (
          <DesignCard key='design-card'>
            <DesignImg key='design-img'>
              <img src={item.path} alt={item.label} />
            </DesignImg>
            <ItemLabel key='item-label'>
              <p>{item.label}</p>
            </ItemLabel>
 
          </DesignCard>
           
        ))}
        </ImgGrid>
 
        <ScanImg onClick={handleNavigation} key='scan-img-button'>
          <h2> Scan Clothes</h2>
        </ScanImg>
     
        </ContentContainer>
        </div>
    </OuterContainer>
   
  );
};
 
const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding-top: 60px;
`;
 
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  padding: 20px;
`;
 
const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: FloralWhite;
  z-index: 1000;
`;
 
const ImgGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
`;
 
const DesignCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 8px;
  border-color: #3d4c1b;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #FFFAF0;
`;
 
const DesignImg = styled.div`
  width: 150%;
  height: 150%;
  margin-bottom: 10px;
 
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 8px;
  }
`;
 
const ItemLabel = styled.div`
  font-size: 1rem;
  font-weight: 500;
`;
 
const ScanImg = styled.div`
  font-size: 20px;
  width:100%;
  max-width: 400px;
  height:auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  border-color: #8B4513;
  background-color: #FDF5E6;
  margin: 20px;
  justify-content: center;
  align-items: center;
 
`;

const WelcomeHeader= styled.div`
  margin-bottom: 5px;
  text-align: center; 
  
  h2 {
    font-size: 30px;
  }

  img {
    width: 100%;          
    max-width: 400px; 
    height: auto;
    
  }
`;
 
 
export default Welcome;


