import 'bootstrap/dist/css/bootstrap.min.css';
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { NavBar } from '../../components/NavBar/NavbarComponent';
import './ProfilePersonalizationStyling.css';
import React, { useState } from "react";
import { Link } from 'react-router-dom';





export const PrefferedStyles =()=>{
    const [selectedStyles, setSelectedStyles] = useState([])

    const styles_dic=[
        { id: 1, label: 'Style 1' ,picPath: ''},
        { id: 2, label: 'Style 2',picPath: '' },
        { id: 3, label: 'Style 3',picPath: '' },
        { id: 4, label: 'Style 4',picPath: '' },
        { id: 5, label: 'Style 5',picPath: '' },
        { id: 6, label: 'Style 6',picPath: '' },
        { id: 7, label: 'Style 7',picPath: '' },
        { id: 8, label: 'Style 8',picPath: '' },
        { id: 9, label: 'Style 9',picPath: '' },
        { id: 10, label: 'Style 10',picPath: '' },
        { id: 11, label: 'Style 11',picPath: '' },
        { id: 12, label: 'Style 12',picPath: '' }
    ]

    const handleToggle =( id)=>{
        setSelectedStyles(prevSelectedStyles => {
    if (prevSelectedStyles.includes(id)) {
      // Remove id from array
      return prevSelectedStyles.filter(styleId => styleId !== id);
    } else {
      // Add id to array
      return [...prevSelectedStyles, id];
    }
  });

    }


    return(
        <Container fluid class= 'container'>
            <Row>{<NavBar/>}</Row>
            <h2 className="title">Pick your preferred styles:</h2>
            <div className="styles-grid">
                {styles_dic.map(style => (
                <div className="style-item" key={style.id}>
                    <label className='style-name'> {style.label} </label>
                    <input
                        type="checkbox"
                        id={`style-${style.id}`} 
                        checked={selectedStyles.includes(style.id)}
                        onChange={() => handleToggle(style.id)}
                        />
                    <label htmlFor={`style-${style.id}`} className="style-label">
                    <div className="image-placeholder"> 
                        <img src= {style.picPath} alt= {style.label}></img>
                    </div>
                    </label>
                </div>
                ))}
            </div>
            <Link to='/Welcome'>
            <button className="continue-button">Continue</button>
            </Link>
            


        </Container>
    )
}

export default PrefferedStyles