import React, { useState } from "react";
import "./ItemScan.css";
import { Button } from "@fluentui/react-components";
import scanImg from "../../images/scanning.png";
import profileImg from "../../images/profile-user.png";
import ScanService from "../../services/TagScanService";
import { useNavigate } from "react-router-dom";
import ScanModal from "./modal/ScanModal";

function ItemScan() {
  const [scanResult, setScanResult] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const nav = useNavigate();

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleSubmit = () => {
    console.log(selectedFile);
    //const fileInput = document.querySelector("#fileInput");
    setScanResult(
      <ScanModal imageData={selectedFile} setShowModal={setShowModal} />
    );
    handleShow();
  };

  return (
    <div className="App">
      {showModal ? scanResult : <></>}
      <div className="container">
        <div className="header">
          <span className="back-arrow" onClick={() => nav(-1)}>←</span>
          <img src={profileImg} alt="Profile" className="profile-icon" />
        </div>

        <h2>Scan your item</h2>
        <p className="scan-description">
          Find a piece of clothing and start scanning!
        </p>
        <div className="scanner-container">
          <div className="scanner">
            <input
              type="file"
              id="fileInput"
              accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            {selectedFile ? (
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Scanned"
                className="uploaded-image"
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <div className="placeholder" onClick={handleClick}>
                📷
              </div>
            )}
          </div>
          <hr />
          <div className="button-container">
            <Button className="scan-button" onClick={handleClick}>
              <img
                src={scanImg}
                alt=""
                style={{
                  position: "absolute",
                  top: "auto",
                  right: "75%",
                  maxWidth: "27px",
                }}
              />
              Scan
            </Button>
            <Button
              className="scan-button"
              onClick={handleSubmit}
              disabled={!selectedFile}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemScan;
