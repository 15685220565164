import React, { useState } from 'react';
import './CommunityStyling.css';
import { NavBar } from '../../components/NavBar/NavbarComponent';

function Community() {
  const [friends, setFriends] = useState([
    { name: 'Arlene McCoy', icon: '🧵' },
    { name: 'Albert Flores', icon: '📍' },
    { name: 'Esther Howard', icon: '🧵' },
    { name: 'Darrell Steward', icon: '👕' },
    { name: 'Chris Strangle', icon: '🧵' },
    { name: 'Hello Kitty', icon: '👖' }
  ]);

  //☰
  return (
    <div className="container">
      <header>
        <row> {<NavBar/>} </row>
        <div className="profile-icon">👤</div>
      </header>
      <div className="add-friends">
        <h2>Add Friends</h2>
        <div className="search-bar">
          <input type="text" placeholder="look for users" />
          <button>🔍</button>
        </div>
      </div>
      <div className="search-friends">
        <h2>Search Friends</h2>
        <div className="search-bar">
          <input type="text" placeholder="Search Friends" />
          <button>🔍</button>
        </div>
      </div>
      <div className="friends-list">
        <ul>
          {friends.map((friend, index) => (
            <li key={index} className="friend-item">
              <div className="friend-icon">{friend.icon}</div>
              <div className="friend-name">{friend.name}</div>
              <div className="friend-options">⋮</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Community;
