import React from 'react';
import styled from 'styled-components';
import needle from '../images/needle.png';
import thread from '../images/Thread.png';
import scissors from '../images/scissors.png'

// Import Space Grotesk font
import '@fontsource/space-grotesk'; // Assuming you use a package manager like npm or yarn

export const FindTools = () => {
    const localPeople = ["Chase", "Shirley", "Naumi" , "Alison", "Alexis"]; // Example data
    const packages = [
        { name: "Silver FlipIt Package", price: "$20" },
        { name: "Gold FlipIt Package", price: "$50" },
        { name: "Platinum FlipIt Package", price: "$80" }
    ];

    return (
        <Container
        style={{
            background:"FloralWhite",
            overflow: "scroll",
        }}>
            <h2
            style={{
                color: '#3d4c1b'
            }}>Find Tools</h2>
            
            
            <Card
            style={{
                background: '#D2B48C',
                border: '2px solid #D2B48C',
                borderRadius: '8px'
                
            }}>
                <CardTitle>Find tools with friends who did the project</CardTitle>
                <List
                style={{
                    background: '#D2B48C'
                }}>
                    {localPeople.map((person, index) => (
                        <ListItem key={index} 
                        style={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'left', 
                            background:'#FFFAF0', 
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: '#3d4c1b'}}>
                        {person}

                       <ItemImg>
                        <img src={needle} alt= 'needle'></img>
                       
                        <img src={thread} alt='thread'></img>
                       
                        <img src={scissors} alt='scissors'></img>
                       </ItemImg>
                        </ListItem>
                    ))}
                </List>
            </Card>

            <Card
            style={{
                backgroundColor: '#999966',
                border: '2px solid #999966',
                borderRadius: '8px'
            }}>
                <CardTitle>Online Tool Shopping</CardTitle>
                    <Link href="https://www.amazon.com" target="_blank" rel="noopener noreferrer"
                    style={{
                        background:'#3d4c1b',
                        margin: '5px',
                        width: '143px',
                        borderStyle: 'solid',
                        borderWidth: '2px',
                        borderColor: '#3d4c1b'
                    }}>
                        Amazon Simple Sewing Kit
                    </Link>

                    <Link href="https://www.ebay.com/" target="_blank" rel="noopener noreferrer"
                    style={{
                        background:'#3d4c1b',
                        margin: '5px',
                        width: '143px',
                        borderStyle: 'solid',
                        borderWidth: '2px',
                        borderColor: ' #3d4c1b'
                    }}>
                        Ebay Simple Sewing Kit
                    </Link>
            
            </Card>

            <Card
            style={{
                backgroundColor:'#3d4c1b',
                border: '2px solid #3d4c1b',
                borderRadius: '8px'
            }}>
                <CardTitle
                style={{
                    color:'white'
                }}>Find tools with us</CardTitle>
                <List
                style={{
                    backgroundColor:'#3d4c1b'
                }}>
                    {packages.map((pkg, index) => (
                        <ListItem key={index}
                        style={{
                            backgroundColor:'#FFFAF0',
                            border: '2px solid #FFFAF0',
                            borderRadius: '8px'
                        }}>
                            {pkg.name} - {pkg.price}
                        </ListItem>
                    ))}
                </List>
            </Card>
        </Container>
    );
}

export default FindTools;

const Container = styled.div`
    padding: 20px;
    max-width: 400px;
    margin: auto;
    font-family: 'Space Grotesk', sans-serif;
    height: 100vh;
`;

const Card = styled.div`
    margin-bottom: 20px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const CardTitle = styled.h3`
    margin-bottom: 10px;
    font-size: 18px;
`;

const List = styled.ul`
    list-style-type: none;
    background-color: #FAF0E6;
    padding: 0;
`;

const ListItem = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    padding: 8px;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px;
`;

const ItemImg= styled.div`
    display: flex;
    gap: 5px;
    margin-left: 50px; 

    img{
        width:25%;
        height: 25%;
        object-fit: contain;
    }
`;

const Link = styled.a`
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    text-decoration: none;
    color: white;
    border: 1px solid #007bff;
    border-radius: 4px;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0056b3;
    }
`;
