import React, { useState } from 'react';
import './FlipCard.css';

const StepCard = ({ stepNum, stepContent, imgPath }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleCardClick = () => {
    setIsFlipped(!isFlipped);
  }

  return (
    <div className={`card ${isFlipped ? 'flipped' : ''}`} onClick={handleCardClick}>
      <div className="card-inner">
        <div className="card-front">
          <div className='card-front-step-num'>
            {stepNum}
          </div>
          <div className='card-front-content'>
            {stepContent}
          </div>
          <div className="flip-icon" onClick={handleCardClick}></div> {}
        </div>
        <div className="card-back">
          <img className="card-back-image" src={imgPath} alt='visual for the step'></img>
        </div>
      </div>
    </div>
  );
}

export default StepCard;
