import axios from "axios";
import { Form } from "react-router-dom";
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const ScanService = class {
  static postTagData = async (formInput) => {

    //console.log("formInput: ", formInput);
    // Processing inputted image in a way that can be sent as a request
    const formData = new FormData();
    formData.append("image", formInput);

    // setting up fetch config based on postman translation
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://tag-scan.cognitiveservices.azure.com/vision/v3.2/read/analyze",
      headers: {
        "Ocp-Apim-Subscription-Key": "0b99243c5b6d4e9c9019e6c520bbc422",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    // Making the POST request
    try {
      const response = await axios
        .request(config)
      return response.headers;
    } catch (error) {
      console.error("Error making POST request", error);
    }
  };

  static getTagData = async (formData) => {
    const visRes = await this.postTagData(formData);
    console.log("visRes: ", visRes["operation-location"]);
    if (!visRes) {
      throw new Error("No response from Azure Vision API");
    } else {
      const operationsUrl = visRes["operation-location"];
      try {
        let status = "";
        let response = "";
        while (status !== "succeeded") {
          await sleep(1000);
          //console.log("Checking status...");
          response = await axios.get(operationsUrl, {
            headers: {
              "Ocp-Apim-Subscription-Key": process.env.REACT_APP_VISION_KEY,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
          status = response.data.status;
        }
        //console.log(response);
        this.printRecText(response.data.analyzeResult.readResults);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      }
    }
  };
  // Prints all text from Read result
  static printRecText = (readResults) => {
    let materials = [];
    let materialTypes = ["cotton", "polyester", "nylon", "wool", "silk", "leather", "linen", "spandex", "rayon", "acrylic"];
    console.log("Recognized text:");
    for (const page in readResults) {
      if (readResults.length > 1) {
        console.log(`==== Page: ${page}`);
      }
      const result = readResults[page];
      if (result.lines.length) {
        for (const line of result.lines) {
          console.log(line.words.map((w) => w.text).join(" "));
          for (const type in materialTypes)
          if(line.words.map((w) => w.text).join(" ").includes(type)){
            materials.push(type);
          }
        }
      } else {
        console.log("No recognized text.");
      }
    }
  };


  s
};

export default ScanService;
