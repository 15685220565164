import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import bow from "../images/bow.png";
import tieDye from "../images/tie-dye.png";
import tote from "../images/tote.png";
import belt from "../images/belt.png";
import petBed from "../images/pet-bed.png";
import rug from "../images/rug.png";
import rags from "../images/towel.png";
import styled from 'styled-components';

// Import the Space Grotesk font
import "@fontsource/space-grotesk";

const DesignSuggestions = () => {
  const [key, setKey] = useState("red");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDifficulty, setSelectedDifficulty] = useState("0");
  const [hasTools, setHasTools] = useState("");
  const nav = useNavigate();

  const colorSelector = {
    Easy: "MediumSeaGreen",
    Medium: "Khaki",
    Hard: "FireBrick",
  };
  const templates = [
    {
      value: 7,
      label: 'Fabric "Paper" Towels',
      img: rags,
      difficulty: "Easy",
      tools: ["Scissors", "Hemming Tape", "Iron"],
      time: "1 hour",
      url: "https://www.papernstitchblog.com/how-to-make-reusable-paper-towels/",
      type: "repurpose",
    },
    {
      value: 2,
      label: "Tie Dye",
      img: tieDye,
      difficulty: "Easy",
      tools: [],
      time: "2 hours",
      url: "https://www.womenshealthmag.com/fitness/a33297101/how-to-tie-dye-shirts/",
      type: "repurpose",
    },
    {
      value: 3,
      label: "Fabric Bows",
      img: bow,
      difficulty: "Easy",
      tools: ["Scissors", "Needle", "Thread"],
      time: "30 minutes",
      url: "https://www.thesprucecrafts.com/how-to-make-fabric-bows-2977738",
      type: "design",
    },
    {
      value: 4,
      label: 'Fabric "Paper" Towels',
      img: rags,
      difficulty: "Easy",
      tools: ["Scissors", "Hemming Tape", "Iron"],
      time: "1 hour",
      url: "https://www.papernstitchblog.com/how-to-make-reusable-paper-towels/",
      type: "repurpose",
    },
    {
      value: 5,
      label: "Tote Bag",
      img: tote,
      difficulty: "Medium",
      tools: ["Scissors", "Needle", "Thread"],
      time: "3 hours",
      url: "https://discover.hubpages.com/art/How-to-Make-a-Bag-from-an-Old-T-Shirt",
      type: "design",
    },
    {
      value: 6,
      label: "Pet Bed",
      img: petBed,
      difficulty: "Medium",
      tools: ["Scissors", "Needle", "Thread"],
      time: "3 hours",
      url: "https://makeit-loveit.com/ombre-fringe-skirt-made-from-old-tshirts",
      type: "design",
    },
    {
      value: 7,
      label: "T-Shirt Rug",
      img: rug,
      difficulty: "Hard",
      tools: ["Scissors", "Needle", "Thread"],
      time: "5 hours",
      url: "https://mypoppet.com.au/makes/upcycle-style-braided-t-shirt-rug/",
      type: "design",
    }
  ];

  const handleSelectChange = (event) => {
    setSelectedDifficulty(event.target.value);
  };
  const handleToolChange = (event) => {
    setHasTools(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredTemplates = templates.filter(({ label, difficulty, tools }) => {
    return (
      label.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedDifficulty === "0" || selectedDifficulty === difficulty) &&
      (hasTools === "" ||
        (hasTools === "true" && tools.length > 0) ||
        (hasTools === "false" && tools.length === 0))
    );
  });

 

  return (
    <Container>
      <div 
        style={{
          padding: "20px",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: "scroll",
          background: "FloralWhite", 
          
        }}
      >
        <style>
        {`
          input::placeholder {
            color: #e3dac9;
            opacity: 1; /* Ensures the white color is fully opaque */
          }
        `}
      </style>
        <input
          type="text"
          placeholder="Hinted search text"
          color = "white"
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ width: "100%", padding: "10px", fontSize: "16px", backgroundColor: "#3d4c1b", marginBottom: "10px" }}
        />
        <div className="filters" style={{ display: "flex", marginTop: "10px", marginBottom: "30px"  }}>
          <Form.Select
            aria-label="Difficulty selector"
            style={{ width: "50%", backgroundColor: "#FAF0E6", marginRight:'20px'  }}
            onChange={handleSelectChange}
          >
            <option value="0">Difficulty</option>
            <option value="Easy">Easy</option>
            <option value="Medium">Medium</option>
            <option value="Hard">Hard</option>
          </Form.Select>
          <Form.Select
            aria-label="Tool selector"
            style={{ width: "50%", backgroundColor: "#FAF0E6"}}
            onChange={handleToolChange}
          >
            <option value="">Tools</option>
            <option value="true">Requires Tools</option>
            <option value="false">No Tools</option>
          </Form.Select>
        </div>
        {searchTerm === "" ? <h2>Designs Suggested For You:</h2> : null}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(1, 1fr)",
            gap: "10px",
            marginTop: "10px",
            marginBottom:'30px'
          }}
        >
          {filteredTemplates.map((template, index) => (
            <div
              key={index}
              onClick={() => nav("/steps")}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: "20px",
              }}
            >
              <img
                src={template.img}
                alt={template.label}
                style={{ width: "33%", marginRight: "10px" }}
              />
              <div style={{ width: "67%", textAlign: "left" }}>
                <div
                  style={{
                    marginBottom: "10px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {template.label}
                </div>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
                  <span
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "15px",
                      padding: "5px 10px",
                      backgroundColor: colorSelector[template.difficulty],
                    }}
                  >
                    Difficulty: {template.difficulty}
                  </span>
                  {template.tools.map((tool, index) => (
                    <span
                      key={index}
                      style={{
                        border: "1px solid #ccc",
                        borderRadius: "15px",
                        padding: "5px 10px",
                      }}
                    >
                      {tool}
                    </span>
                  ))}
                  <span
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "15px",
                      padding: "5px 10px",
                    }}
                  >
                    Time: {template.time}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  font-family: "Space Grotesk", sans-serif;
`;

export default DesignSuggestions;
