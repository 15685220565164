import logo from "./logo.svg";
import React from "react";
import "./App.css";
import '@fortawesome/fontawesome-free/css/all.min.css';

 
import { Route, Routes } from "react-router-dom";
import ItemScan from "./pages/ItemScan/ItemScan";
import DesignSearch from "./pages/DesignSearch";
import { ProjectDone } from "./pages/ProjectDone";
import { Steps } from "./pages/Steps/Steps"
import { Help } from "./pages/Help"
import { FindTools } from "./pages/FindTools"
import { Impact } from "./pages/ImpactPageFolder/ImpactPage";
import  Welcome  from './pages/Welcome';
import CommunityPage from "./pages/CommunityPage/CommunityPage";
import SignIn from "./pages/SignInPage/SignIn";
import { PrefferedStyles } from "./pages/ProfilePersonalization/ProfilePersonalizationPage";
import ImageAnalyzer from "./pages/GarmentScan/GarmentScan";
import DesignScreen from './pages/DesignSummary/DesignSummary';
import GarmentBox, { getGarmentSuggestions } from "./pages/gptSuggestions";

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/ProjectDone" element={<ProjectDone />} />
        <Route path="/SignIn" element={<SignIn />} />
        <Route path="/UserImpact" element={<Impact />} />
        <Route path="/designSearch" element={<DesignSearch />} />
        <Route path="/scanimg" element={<ImageAnalyzer />} />
        <Route path="/preferredStyles" element={<PrefferedStyles />} />
        <Route path="/Congrats" element={<ProjectDone />} />
        <Route path="/ItemScan" element={<ItemScan />} />
        <Route path="/Steps" element={<Steps />} />
        <Route path="/Help" element={<Help />} />
        <Route path="/FindTools" element={<FindTools />} />
        <Route path="*" element={<p>404 Item Not Found</p>} />
        <Route path = '/Community' element = {<CommunityPage/>} />
        <Route path='/DesignSummary' element={<DesignScreen/>}/>
        <Route path='/gpt' element={<GarmentBox/>}/>
      </Routes>
     
    </div>
  );
}
 
export default App;