import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";

function ScanModal({ imageData, setShowModal }) {
  const [show, setShow] = useState(false);
  const [clothingType, setClothingType] = useState("");
  const handleClose = () => {
    setShow(false);
    setShowModal(false);
  };

  const handleSelectChange = (event) => {
    setClothingType(event.target.value);
  };
  const nav = useNavigate();
  const [caption, setCaption] = useState("");
  const clothingTypes = [
    "shirt",
    "pants",
    "jeans",
    "dress",
    "skirt",
    "shoe",
    "hat",
    "bedsheet",
    "sweater",
    "jacket",
  ];

  const analyzeImageFromInput = async (imageData) => {
    const data = new FormData();
    data.append("image", imageData);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url:
        process.env.REACT_APP_VISION_ENDPOINT +
        "computervision/imageanalysis:analyze?features=caption,read&model-version=latest&language=en&api-version=2024-02-01",
      headers: {
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_VISION_KEY,
        "Content-Type": "image/jpeg",
      },
      data: imageData,
    };
    console.log(config);
    try {
      let result = await axios.request(config);
      console.log(result);
      const iaResult = result.data;
      if (iaResult.captionResult) {
        setClothingType(`${extractClothingType(iaResult.captionResult.text)}`);
      }
    } catch (error) {
      console.error("Error making POST request", error);
    }
    setShow(true);
  };

  const extractClothingType = (text) => {
    for (let i = 0; i < clothingTypes.length; i++) {
      if (text.includes(clothingTypes[i])) {
        return clothingTypes[i];
      }
    }
    return "unknown";
  };

  const handleNav = () => {
    nav("/designSearch", { state: { caption: caption } });
  };

  useEffect(() => {
    analyzeImageFromInput(imageData);
  }, []);

  return (
    <>
      {show ? (
        <div
          className="modal show"
          style={{ display: "block", position: "initial" }}
        >
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title center>Clothing scan</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Your garment is a:
              <Form.Select
                aria-label="Difficulty selector"
                style={{ maxWidth:"34%",  margin: "0 10px", whiteSpace: "nowrap" }}
                onChange={handleSelectChange}
                value={clothingType}
              >
                {clothingTypes.map((type) => (
                  <option value={type}>{type}</option>
                ))}
              </Form.Select>
            </Modal.Body>
            <Modal.Footer>
              <Row>
                <Button variant="primary" onClick={handleNav}>
                  Yes, I want to search designs
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  No, I want to input new items
                </Button>
              </Row>
            </Modal.Footer>
          </Modal>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default ScanModal;
